@font-face {
	font-family: 'Simply Cricket';
	font-display: auto;
	src: url('../fonts/SimplyCricket-Book.eot');src: url('../fonts/SimplyCricket-Book.eot?#iefix') format('embedded-opentype'),url('../fonts/SimplyCricket-Book.woff2') format('woff2'),url('../fonts/SimplyCricket-Book.woff') format('woff'),url('../fonts/SimplyCricket-Book.svg#wf') format('svg');
}

@font-face {
	font-family: 'Simply Cricket Bold';
	font-display: auto;
	src: url('../fonts/SimplyCricket-Bold.eot');src: url('../fonts/SimplyCricket-Bold.eot?#iefix') format('embedded-opentype'),url('../fonts/SimplyCricket-Bold.woff2') format('woff2'),url('../fonts/SimplyCricket-Bold.woff') format('woff'),url('../fonts/SimplyCricket-Bold.svg#wf') format('svg');
}

@font-face {
	font-family: 'Simply Demi Bold';
	font-display: auto;
	src: url('../fonts/SimplyCricket-Demibold.eot');src: url('../fonts/SimplyCricket-Demibold.eot?#iefix') format('embedded-opentype'),url('../fonts/SimplyCricket-Demibold.woff2') format('woff2'),url('../fonts/SimplyCricket-Demibold.woff') format('woff'),url('../fonts/SimplyCricket-Demibold.svg#wf') format('svg');
}

@font-face {
	font-family: 'Simply Cricket Medium';
	font-display: auto;
	src: url('../fonts/SimplyCricket-Medium.eot');src: url('../fonts/SimplyCricket-Medium.eot?#iefix') format('embedded-opentype'),url('../fonts/SimplyCricket-Medium.woff2') format('woff2'),url('../fonts/SimplyCricket-Medium.woff') format('woff'),url('../fonts/SimplyCricket-Medium.svg#wf') format('svg');
}

@font-face {
  font-family: 'icons';
  src:  url('../fonts/cricket-icons.eot?a9m31m');
  src:  url('../fonts/cricket-icons.eot?a9m31m#iefix') format('embedded-opentype'),
    url('../fonts/cricket-icons.ttf?a9m31m') format('truetype'),
    url('../fonts/cricket-icons.woff?a9m31m') format('woff'),
    url('../fonts/cricket-icons.svg?a9m31m#icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}