.modal {
    width: auto;
    max-width: 100%;
    position: relative;
    z-index: 1;
    transform: scale(0.8);
    transition: transform 0.6s;
    display: none;
    
    &-framed {
        background: #fff;
        padding: map-get($layout, gutters);
        border-radius: 12px;
        width: 96%;
        max-width: 650px;
    }

    @media (max-width: 767px) {
        width: 96%;
        padding: 40px;
    }

    .is-open & {
        transform: scale(1);
        display: block;
    }

    &-container {
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 11;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.4s, visibility 0s linear 0.4s;

        &.is-open {
            transition-delay: 0s;
            opacity: 1;
            visibility: visible;
        }
        
        .modal {
            max-height: calc(100vh - 60px);
            
            .modal-body {
                height: 90vh;
            }
            
            img {
                max-height: 90%;
                border: 1px solid map-get($colors, gray_600);
            }
        }
    }

    &-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
        background: rgba(0,0,0, 0.3);
    }
    
    img {
        max-width: 100%;
    }

    &-close {
        margin: 0;
        padding: 0;
        display: block;
        border: none;
        position: absolute;
        top: 0px;
        right: 6px;
        background: none;
        padding: 10px;
        font-size: rem(36);
        line-height: 1;
        color: map-get($colors, gray_600);
        cursor: pointer;

        & > * {
            pointer-events: none;
        }

        @media (max-width: 767px) {
            font-size: rem(32);
            right: 0;
            top: 0;
            padding: 12px;
        }
    }
}
