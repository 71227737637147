.form-group {
    position: relative;
    margin-bottom: 12px;

    .flex {
        margin: 0;

        .clear {
            float: right;
            color: map-get($colors, gray_500);
            font-size: 14px;
        }
    }

    .control-label {
        display: block;
        margin-bottom: 6px;
        font-family: map-get($fonts, cricket);
    }

    .control-text,
    .control-select,
    .control-textarea {
        cursor: inherit;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        height: 38px;
        border: 1px solid map-get($colors, gray_600);
        padding: .25rem .5rem;
        margin: 0;
        display: block;
        width: 100%;
        background: map-get($colors, gray_50);
    }

    .control-textarea {
        height: 120px;
        resize: none;
    }


    .control-select {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSI4LjA3NSIgdmlld0JveD0iMCAwIDEzIDguMDc1Ij4NCiAgPHBhdGggaWQ9IlBhdGhfMiIgZGF0YS1uYW1lPSJQYXRoIDIiIGQ9Ik02LjUsOC4wNzUsMTMsMS4yODMsMTEuNzczLDAsNi41LDUuNSwxLjIzNSwwLDAsMS4yODNaIiBmaWxsPSIjMjIwMzAzIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4NCjwvc3ZnPg0K");
        background-repeat: no-repeat;
        background-position: calc(100% - 12px) center;
        padding-right: 2rem;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;       /* Remove default arrow */
    }

    .icon-chevron-down {
        position: absolute;
        right: 18px;
        color: map-get($colors, black);
        font-size: 8px;
        top: 53px;
    }

    .selection {
        background: map-get($colors, greenShade);
        border-radius: 25px;
        width: fit-content;
        padding: 9px 12px;
        color: white;
        margin-top: 11px;
        display: flex;
        align-items: center;
        line-height: 0;
        padding-top: 14px;
        font-size: 14px;
        margin-right: 5px;

        .icon-close {
            font-size: 10px;
            font-family: map-get($fonts, cricket);
            margin-right: 3px;
        }
    }
}

.label-offset {
    margin-top: 30px;
}

.control-fieldset {
    margin-bottom: map-get($layout, gutters) / 2;
}

.control-legend {
    display: block;
    color: map-get($colors, green);
    text-transform: uppercase;
    font-size: rem(18);
    margin-bottom: rem(8);
}

.price-fields {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    .text-label {
        padding: .25rem .25rem 0;
        margin-top: .25rem;

        &:first-child {
            padding-left: 0;
        }
    }
}

.form-heading {
    background: map-get($colors, gray_900);
    color: #fff;
    font-size: rem(22);
    font-family: map-get($fonts, cricketBold);
    text-align: center;
    padding: 4px 12px 8px;
    margin-bottom: map-get($layout, gutters);
}

.side-heading {
    padding: 4px 12px 8px;
    margin-bottom: map-get($layout, gutters);
}

.sort-form {
    position: relative;

    .sort-handle {
        cursor: move;
        width: 30px;
        color: color(med-gray);
    }

    [data-sort] {
        opacity: 1;
        transition: opacity .4s ease-in-out;
    }

    .loader-container {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform-origin: 50% 50%;
        transform: scale(0, 0);
        transition: opacity, transform .4s ease-in-out .1s;

        &.hidden {
            display: block;
        }
    }

    &.loading {
        [data-sort] {
            opacity: .3;
        }

        .loader-container {
            opacity: 1;
            transform: scale(1, 1);
        }
    }
}
