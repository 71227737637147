.header {
    height:137px;

    .logo-container {
        padding-left: 0;
    }

    .nav-list {
        text-align: left;

        &-item {
            margin: 0 11px;
            display: inline;

            &.has-dropdown {
                position: relative;
            }

            &.has-dropdown:hover .subnav {
                display: block;
            }
        }

        &-link {
            font-family: map-get($fonts, cricketBold);
            color: black;
            font-size: 14px;
            &.active {
                color: map-get($colors, greenShade);
            }
        }
    }

    // .page-nav {
    //     margin-left: 35px;
    // }

    .subnav {
        display: none;
        position: absolute;
        width: max-content;
        top: 24px;
        left: 0px;
        color: white;
        background:map-get($colors, gray_700);
        padding: 8px;

        &-item {
            margin: 8px 12px;
            text-align: left;
        }

        &-link {
            color: white;
        }
    }
}

.page-header-lower {
    background-color: map-get($colors, gray_700);
    color: map-get($colors, white);
    height: 50px;

    .browse-all {
        color: map-get($colors, white);
        font-family: map-get($fonts, cricketMedium);

        .icon-quad-circles {
            font-size: 20px;
            margin-right: 3px;
        }
    }

    a {
        color: map-get($colors, white);
    }

    .browse-all {
        position: relative;
        padding: 14px 0;
        width: fit-content;

        .dropdown-content {
          display: none;
          position: absolute;
          top: 51px;
          border: solid 1px map-get($colors, gray_600);
          background-color: map-get($colors, white);
          min-width: 200px;
          box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
          z-index: 10000;
          padding-bottom: 20px;
          border-bottom-left-radius: 23px;
          border-bottom-right-radius: 23px;

          ul {
              li {
                  padding: 11px 14px;
                  display:flex;
                  align-items: center;
                  //position: relative;
                  &:hover {
                      background: #e5e5e5;
                  }
                  a {
                      color:map-get($colors, black) ;
                      width: 100%;
                      display: block;
                  }

                  .icon {
                      font-size: 6px;
                      color: black;
                  }

                  .submenu {
                    display: none;
                    color: green;
                    position: absolute;
                    left: 199px;
                    z-index: 99999999999;
                    top: -1px;
                    border: solid 1px map-get($colors, gray_600);
                    background-color: map-get($colors, white);
                    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
                    padding-bottom: 20px;
                    border-bottom-left-radius: 23px;
                    border-bottom-right-radius: 23px;
                    -webkit-column-count: 3;
                    -moz-column-count: 3;
                    column-count: 3;
                    padding: 27px 36px;
                    li {
                        min-width: 270px;
                        padding: 1px 14px;
                        font-size: 14px;
                    }
                  }
                  &.dropdown:hover > .submenu {
                    display: block;

                  }
              }
            //   li.dropdown:hover > .submenu {
            //       display: block;
            //   }
          }
        }

        &:hover .dropdown-content {
          display: block;
        }
    }

    .hide-element {
        border: 0;
        clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .search-bar {
        display:inline-flex;
        border-radius: 14px;
        height: 27px;
        background-image:none;
        background-color:white;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        margin: 0 10px;

        &:last-child {
            margin: 0;
        }

        &:focus {
            outline: none;

        }
    }

    .text-control {
        height: 27px;
        border-radius: 14px 0 0 14px;
        border:none;
        padding: 0 0 0 15px;
        font-size: 11px;
        width: 139px;

        &.wide {
            width: 230px;
        }
        @media only screen and (max-width: 1230px){
            width: 100px;
        }
    }

    .button {
        text-transform: none;
        font-family: map-get($fonts, cricket);
        font-size: 11px !important;
        margin-left: 10px;
        cursor: pointer;
        line-height: 0;
        height: 27px;
        padding: 0.4rem 1.125rem 0.6rem;
        border-radius: 14px
    }

    .icon-search {
        margin-right: 3px;
    }

    .advanced-search {
        color: map-get($colors, black);
        padding: 0.5rem 1.125rem 0.6rem;
    }
}

.back-to-brand-center {
    position: absolute;
    top: 10px;
    right: 0;
    font-size: 12px;
}

.brand-link{
    a{
        color: black !important;
        text-decoration: underline;
        font-weight: 600;
        @media only screen and (max-width: 1200px){
            font-size: 0.8rem;
        }
    }
}
.user-block{
    font-family: "Simply Cricket Medium", sans-serif;
    span,a{
        font-family: "Simply Cricket Medium", sans-serif;
    }
}