[data-expandset],
.expandset {
    [data-expandset-panel] {
        overflow: hidden;
        opacity: 0;
        max-height: 0;
        transition: opacity .3s ease-in-out, max-height .3s ease-in-out;

        @media (max-width: 767px) {
            font-size: rem(14);
        }

        &.is-open {
            max-height: 500px;
            display: block;
            opacity: 1;
            
            &:not(.transition) {
                max-height: none !important;
            }
        }

        &.transition {
            overflow: hidden;
        }
    }

    [data-toggle-expandset] {
        overflow: hidden;
        cursor: pointer;
        
        &.is-open.hide-on-open {
            display: none;
        }
    }
}