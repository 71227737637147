.pagination {
	text-align: center;
	padding: 20px;

	.nav-list {
	    margin: 0;
	    padding: 0;
	    list-style: none;
	    font-size: 0;

	    li {
		display: inline-block;
	    }

	    span,
	    a {
		display: block;
		padding: 13px;
		border: 1px solid color(ltGray);
		border-right: none;
		border-radius: 10px;
		font-size: 13px;
		line-height: 1;
		margin-right: 10px;

		background: map-get($colors, green);
        	color: #fff;

		&:hover {
			background: map-get($colors, greenShade);
			color: #fff;
		}

		&.ellipsis {
		    color: color(mdGray);
		    background: #fff;
		    cursor: default;
		}
	    }


	    :last-child > a,
	    :last-child > span {
		border-right: 1px solid color(ltGray);
	    }

	    .current {
		background: map-get($colors, limeGreen);
        	color: #000;
		cursor: default;


		&:hover {
		    background: color(blue);
		}
	    }

	    .disabled {
		background: #fff;
		color: #fff !important;
		cursor: default;

		&:hover {
		    background: color(ltGray);
		    color: color(mdGray);
		}
	    }

	    .prev {
		// border-radius: 4px 0 0 4px;

		color: black;
		font-size: 20px;
		background-color: #fff;
		a{
			color: black;
			font-size: 20px;
			background-color: #fff;
		}

	    }

	    .next {
		// border-radius: 0 4px 4px 0;
		&>a{
			color: black;
			font-size: 20px;
			background-color: #fff;
		}
	    }
	}
    }