.elongated-btn {
    background: map-get($colors, gray_100);
    min-width: 440px;
    height: 69px;
    border-radius: 50px;
    padding-left: 25px;
    margin-top: 5px;
    position: relative;
    
    @media screen and (max-width: 449px) {
        min-width: 100%;
    }
    
    .collection-name {
        font-size: 1.2rem;
        margin-bottom: 5px;
        color: #404041;
    }
    
    .view-btn {
        background: rgb(76,178,38);
        background: -webkit-linear-gradient(bottom, rgba(76,178,38,1) 35%, rgba(194,213,0,1) 100%);
        background: -moz-linear-gradient(bottom, rgba(76,178,38,1) 35%, rgba(194,213,0,1) 100%);
        background: -o-linear-gradient(bottom, rgba(76,178,38,1) 35%, rgba(194,213,0,1) 100%);
        background: linear-gradient(to top, rgba(76,178,38,1) 35%, rgba(194,213,0,1) 100%);
        border-radius: 50%;
        color: white;
        height: 55px;
        text-align: center;
        width: 55px;
        display: flex;
        align-items: center;
        margin-left: 20px;
        justify-content: center;
        padding-bottom: 4px;
        font-family: map-get($fonts, cricket);
        position: absolute;
        right: 6px;
        top: -13px;
        font-size: rem(15);
    }
}

.rate-plans-container {
    max-width: 1000px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 490px);
    justify-content: space-between;
    grid-gap: 15px;
    margin: 25px auto;
    
    @media screen and (max-width: 1022px) {
        grid-template-columns: auto auto;
    }
    
    @media screen and (max-width: 914px) {
        grid-template-columns: auto;
        justify-content:center;
    }
    
    @media screen and (max-width:449px) {
        display: block;
    }
    
    &>* {
        flex: 0 0 50%;
    }
    
    .flex + .flex {
        margin-top: 5px;
    }
    
    // &:after {
    //     content: "";
    //     flex: auto;
    // }
}

// .rate-plans-container>* {
//     flex: 0 0 33.3333%;
// }
