.bread-crumbs {
    display: block;
    margin: 15px auto 0 auto;
    text-align: center;
    a {
        &:after {
            // content: ">";
            // margin: 0 6px;
        }

        &:last-child:after {
            content: "";
        }
    }
}