[v-cloak] {
    display: none;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, 
.fade-leave-to {
  opacity: 0;
}