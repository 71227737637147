.button {
    display: inline-block;
    border: none;
    background: none;
    font-family: map-get($fonts, cricketBold);
    font-weight: 400;
    font-size: rem(16);
    line-height: 1;
    text-transform: uppercase;
    height: 38px;
    border-radius: 20px;
    padding: 9px 20px 10px 20px;
    cursor: pointer;
    
    &.not-bold {
        font-family: map-get($fonts, cricketMedium) !important;
    }
    
    &-small {
        font-family: map-get($fonts, cricketDemibold);
        font-size: rem(16);
        height: 35px;
        border-radius: 18px;
        padding: rem(11) rem(18);
    }
    
    &-tiny {
        height: 27px;
        padding: rem(8) rem(18);
    }
    
    & + & {
        margin-left: rem(8);
    }
    
    &-primary {
        background: map-get($colors, green);
        color: #fff;
        
        &:hover,
        &:active,
        &:focus {
            background: map-get($colors, greenShade);
            color: #fff;
        }
    }
    
    &-secondary {
        background: map-get($colors, limeGreen);
        color: #000;
        
        &:hover,
        &:active,
        &:focus {
            background: map-get($colors, limeGreenShade);
            color: #000;
        }
    }
}

input.button {
    padding: 7.5px 20px 10px 20px;
}

button.button {
    padding: 3.5px 20px 10px 20px;
}

.favorite-heart {
    width: 28px;
    display: block;
    
    img {
        display: block;
        max-width: 100%;
    }
}