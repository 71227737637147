.page-footer {
    background: map-get($colors, gray_700);
    color: map-get($colors, white);
    
    h5 {
        font-size: 18px;
        margin-bottom: 25px;
    }
    
    p {
        font-size: 12px;
    }
    
    a {
        color: map-get($colors, white);
    }
    
    .divider {
        width: 1px;
        height: 100%;
        background: white;
        position: absolute;
        left: -50px;
        top: 0;
    }
    
    .footer-nav {
        font-size: 13px;
        font-family: map-get($fonts, cricketMedium);
        li {
            margin-bottom: 15px;
            
            .subnav {
                margin-left: 25px;
                li {
                    margin-bottom: 0px;
                }
            }
        }
    }
}