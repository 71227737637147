.message {
    margin: map-get($layout, gutters) / 2 auto;
    max-width: calc(1280px - 26px);
    padding: map-get($layout, gutters) / 2;
    font-weight: 700;
    font-size: 14px;

    &.success {
        color: map-get($colors, success);
        background-color: map-get($colors, successLight);
    }
    
    &.error {
        color: map-get($colors, error);
        background-color: map-get($colors, errorLight);
    }
}