$layout: (
    width: 1280px,
    header-width: 92vw,
    gutters: 40px,
    columns: 12
);

$colors: (
    green: #5cbb4c,
    greenShade: #43B02A,
    limeGreen: #becb00,
    limeGreenShade: #B5C101,
    blue: #269ce0,
    red: #CF292A,
    gray_50: #f9f9f9,
    gray_100: #ebebeb,
    gray_150: #dbdbdb,
    gray_200: #959595,
    gray_500: #c4c4c4,
    gray_600: #707070,
    gray_700: #404041,
    gray_800: #2D332C,
    gray_900: #1c1818,
    success: #4c8d0a,
    successLight: #f7fef1,
    error: #ff1103,
    errorLight: #fff1f0,
    black: #000,
    white: #fff
);

$fonts: (
    cricket: ('Simply Cricket', sans-serif),
    cricketBold: ('Simply Cricket Bold', sans-serif),
    cricketDemibold: ('Simply Cricket Demi Bold', sans-serif),
    cricketMedium: ('Simply Cricket Medium', sans-serif),
    icons: ('icons')
);

$icons: (
    chevron-up: "\e900",
    chevron-down: "\e904",
    chevron-left: "\e905",
    chevron-right: "\e906",
    close: "\e901",
    delete: "\e9ac",
    image: "\e90d",
    quad-circles: "\e902",
    pencil: "\e907",
    search: "\e903"
);

%clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}