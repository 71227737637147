.search-results-detail {
    padding: 0.5rem 1.5rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.search-results-info {
    flex-grow: 1;
}

.search-results-key {
    font-family: map-get($fonts, cricketBold);
    color: map-get($colors, gray_600);
    margin-right: 2px;
}

.promo-block-actions {
    display: block;
    margin: 1.25rem auto 0 auto;
    text-align: center;
}

.flex.has-gutters-none {
    margin-top: 0;
}