[data-tabset].transitioning {
    overflow: hidden;
}

[data-toggle-tabset] {
    * {
        pointer-events: none;
    }
}

.tabset-body-container {
    position: relative;
}

[data-tabset-body] {
    display: none;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;

    &.transitioning {
        // display: block; // this was cause the body to flicker on transitions
        background: #fff;
        will-change: opacity;
        overflow: hidden;
    }

    &.open {
        display: block;
        opacity: 1;
        height: auto;
        position: relative;
    }
}