.quick-tip {
  color: #404041;
  display: block;
  border: 1px solid #5cbb4c;
  padding: 16px 38px;
  border-radius: 8px;
  font-size: 22px;
  text-align: center;

  a {
    text-decoration: underline;
  }

  strong {
    font-family: "Simply Cricket Bold", sans-serif;
  }

  &.mini {
    display: inline-block;
    border-width: 2px;
    box-shadow: 0 4px 6px 1px rgba(0, 0, 0, .1);
    padding: 12px 18px;
    font-size: 16px;
    
    a {
      font-family: "Simply Cricket Bold", sans-serif;
      text-decoration: none;
    }
  }

}