.table {
    width: 100%;
    table-layout: fixed;
    border-collapse: initial;

    th {
        font-family: map-get($fonts, cricketBold);
        font-weight: 400;
        font-size: rem(18);
        line-height: 1;
        text-align: center;
        color: map-get($colors, gray_700);
        height: 40px;
        padding: 0 10px;

        span {
            display: block;
            background-color: map-get($colors, gray_100);
            height: 40px;
            border-radius: 20px;
            padding: 9px 30px;
        }
    }

    td {
        text-align: center;
        font-size: rem(18);
        padding: 20px 30px;
        border-bottom: 1px solid map-get($colors, gray_600);
    }



    &-small {
        th {
            font-size: rem(14);
            padding: 0 5px;
            height: 28px;

            span {
                padding: 5px 15px;
                height: 28px;
            }
        }

        td {
            font-size: rem(14);
            padding: 10px 15px;
        }
    }

    .table-btn {
        font-size: 0.95rem;
        text-transform: none;
        font-family: map-get($fonts, cricketMedium);
    }

    .options-col {
        display: flex;

        & > div {
            margin: 2px;
        }
    }

    .sort-cursor {
        cursor: pointer;
    }

    .no-padding {
        padding: 0px 0px;
    }
}