#preview-image-front {
    img {
        display: block;
        text-align: center;
        margin: 0 auto;
    }
}

.over-title {
    margin-top: 30px;
}