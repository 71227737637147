.promo-block, .search-result {
    border-radius: 15px;
    border: 1px solid map-get($colors, gray_150);
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, .16);
    background-color: map-get($colors, gray_100);
    height: 100%;
    display: flex;
    flex-direction: column;

    &-image {
        display: block;
        max-width: 100%;
        object-fit: cover;
        width: 100%;
        height: 250px;
        max-height: 250px;
        border-radius: 15px 15px 0 0;
        margin-bottom: auto; //Center Images that do not sapn div
        flex-grow: 1;
    }

    &-detail {
        background-color: map-get($colors, gray_100);
        color: map-get($colors, gray_600);
        padding: rem(30) rem(24);
        display: flex;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-radius: 0 0 15px 15px;

        &-column {
            flex-direction: column;

            .promo-block-info {
                margin-bottom: 16px;
                width: 100%;
            }
        }
    }

    &-info {
        flex-grow: 1;
    }

    &-tag {
        font-size: rem(13);
        font-family: map-get($fonts, cricketBold);
        font-weight: 400;
        text-transform: uppercase;
    }

    &-title {
        font-size: rem(13);
        font-family: map-get($fonts, cricketBold);
        font-weight: 400;
        max-width: 210px;
        color: map-get($colors, gray_700);
    }

    &.placeholder {
        opacity: .5;
    }
}

.promo-thumb {
    img {
        display: block;
        max-width: 100%;
    }
}

.promo-popup {
    padding: 40px 30px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 550px;
    height: auto;
    max-width: 95%;
    max-height: 90vh;
    z-index: 1001;
    box-shadow: 0 5px 6px 2px rgba(0, 0, 0, .35);
    transform-origin: 50% 50%;
    transform: scale(0, 0);
    opacity: 0;
    transition: all .3s ease-in-out;
    display: flex;
    flex-direction: column;
    background: #fff;


    &.active {
        opacity: 1;
        transform: scale(1, 1);
    }

    &.double {
        width: 900px;
    }

    img {
        display: block;
        max-width: 100%;
        max-height: 97%;
        margin: 0 auto;
        border: 2px solid color(body);
    }

    [data-close="promo-popup"] {
        position: absolute;
        top: 12px;
        right: 16px;
        font-size: 32px;
        font-family: map-get($fonts, cricketBold);
    }
}

.placeholder-image {
    padding: map-get($layout, gutters) / 2;
    padding-bottom: 0;
    text-align: center;

    .icon {
        font-size: 104px;
        color: map-get($colors, gray_500);
    }
}

.promo-block{
    div{
        align-items: center;
        min-height: 6rem;
        div{
            align-self: center !important;
            min-height: auto;
        }
        @media only screen and (max-width: 1274px){
            a{
                width: 50%;
            }
            a.button{
                width: 100%;
            }
        }
    }
    .promo-block-detail {
        padding: 1rem 1.5rem;
    }
    .promo-block-actions {
        margin: 0;
    }
    .promo-block-title {
        padding-bottom: 0 !important;
        padding-right: 0.5rem;
        &.two-line-promo{
            margin-top: 0rem;
        }
    }
}

    .promo-block{
        .button{
            font-size: 0.8rem;
            height: 34px;
            @media only screen and (max-width: 1274px){
                font-size: 0.7rem;
                height: 32px;
            }
        }
    }

.pull-right{
    .favorite-heart{
        position: absolute;
        right: 1rem;
    }
}

.call-out {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 18px;
    padding: 20px;

    &-heading {
        font-family: "Simply Cricket Bold", sans-serif;
        font-size: 25px;
        color: #5cbb4c;
        margin-bottom: 10px;
        font-weight: 700;
        min-height: 0 !important;
    }
}
