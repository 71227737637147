/*doc
---
title: Loader
name: loader
category: User Feedback
---
```html_example
<div class="loader-container">
	<div class="fountainG fountainG_1"></div>
	<div class="fountainG fountainG_2"></div>
	<div class="fountainG fountainG_3"></div>
	<div class="fountainG fountainG_4"></div>
	<div class="fountainG fountainG_5"></div>
	<div class="fountainG fountainG_6"></div>
	<div class="fountainG fountainG_7"></div>
	<div class="fountainG fountainG_8"></div>
</div>
```
*/

.loader-container {
	position: relative;
	width: 132px;
	margin: 20px auto;
    height: 100%;
    display: flex;
    align-items: center;
    
    &.loaded {
        display: none;
    }
	
	&.hidden {
		display: none;
	}
	
	.fountainG{
	    background-color:rgb(176,176,176);
	    width:16px;
	    height:16px;
	    animation-name:bounce_fountainG;
	    animation-duration:1.035s;
    	animation-iteration-count:infinite;
    	animation-direction:normal;
    	transform:scale(.3);
    	border-radius:10px;
    }

    .fountainG_1{
    	left:0;
    	animation-delay:0.416s;
    }
    
    .fountainG_2{
    	left:16px;
    	animation-delay:0.5225s;
    }
    
    .fountainG_3{
    	left:33px;
    	animation-delay:0.619s;
    }
    
    .fountainG_4{
    	left:49px;
    	animation-delay:0.7255s;
    }
    
    .fountainG_5{
    	left:66px;
    	animation-delay:0.832s;
    }
    
    .fountainG_6{
    	left:82px;
    	animation-delay:0.9385s;
    }
    
    .fountainG_7{
    	left:99px;
    	animation-delay:1.035s;
    }
    
    .fountainG_8{
    	left:115px;
    	animation-delay:1.1415s;
    }
}

@keyframes bounce_fountainG{
	0%{
	transform:scale(1);
		background-color:rgba(120,120,120,0.97);
	}

	100%{
	transform:scale(.3);
		background-color:rgb(255,255,255);
	}
}