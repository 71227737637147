.search-sidebar {
    border: 1.5px solid map-get($colors, gray_600);
    border-radius: 15px;
    height: fit-content;
    padding: 20px;
    
    & .sidebar-title {
        background: map-get($colors, gray_700);
        max-width: 324px;
        margin: -22px -22px 0 -22px;
        height: 100%;
        padding: 15px 20px;
        color: white;
        font-size: 17px;
        font-family: map-get($fonts, cricketDemibold);
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        text-transform: uppercase;
    }
    
}
