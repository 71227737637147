.builder-height {
    box-shadow: 8px 8px 25px 1px rgba(0, 0, 0, .15);
    border: 1px solid map-get($colors, gray_600);
    margin:auto; //center preview pdf

    .loader-container {
        padding-top: 30px;
    }
}

.builder-canvas {
    height: 0;

    &.loaded {
        display: block;
    }
}

//request file page styles
.request-file {
    .promo {
        margin-top: 30px;
        text-align: center;
    }
    .promo-thumb {
        img {
            display: block;
            margin: 0 auto;
        }
    }

    form {
        margin-top: 30px;

        h1 {
            margin-bottom: 30px;
        }

        .note {
            margin-bottom: 30px;
        }
    }
}

.side-label {
    // padding: 10px !important;
    text-transform: uppercase;
    margin-bottom: 10px;
    background-color: grey;
    color: white;
    border-radius: 30px;
}

.active-side {
    background-color: black;
    border-radius: 20px;
    color: white;
    padding: 0;
    a {
        color: white;
    }
}

.side-tab {
    padding: 4px 5px 6px 5px !important;
    font-weight: 600;
    text-align: center;
    color: white !important;
    font-family: "Simply Cricket", sans-serif;

    a {
        color: white;
    }

    & > .next {
        // width: 100%;
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 16px;
        text-transform: uppercase;
        color:white;
    }
}

