@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
      @return $number / ($number * 0 + 1);
    }
  
    @return $number;
  }

@function rem($size) {
    @return (strip-unit($size) / 16) * 1rem;
}