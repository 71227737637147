@import './variables';
@import './functions';
@import './fonts';
@import './reset';
@import './typography';
@import './icons';
@import './layout';

// baseline styles

*, *:before, *:after {
    box-sizing: border-box;
}

html, body {
    width: 100%;
    overflow-x: hidden;
}

html {
    scroll-behavior: smooth;
}

@each $key, $value in $colors {
    *.is-#{$key} {
        color: $value;
    }
}

.img-responsive {
    max-width: 100%;
    height: auto;
}

.screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important;

    &:focus {
        background-color: #eee;
        clip: auto !important;
        clip-path: none;
        color: #444;
        display: block;
        font-size: 1em;
        height: auto;
        left: 5px;
        line-height: normal;
        padding: 15px 23px 14px;
        text-decoration: none;
        top: 5px;
        width: auto;
        z-index: 100000; /* Above WP toolbar. */
    }
}

.hidden {
    display: none;
}

.mobile-visible {
    display: none !important;
}

@media (max-width: 767px) {
    .mobile-visible {
        display: block !important;
    }

    .mobile-hidden {
        display: none !important;
    }
}

// storybook

.sb-show-main {
    min-height: 100vh;
}