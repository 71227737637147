.hero-slider {
    position: relative;
    
    &-background {
        object-fit: cover;
        display: block;
        width: 100%;
    }
    
    .glide__arrows {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: calc(100% - 50px);
        height: 28px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: auto;
        pointer-events: none;
    }
    
    .glide__arrow {
        pointer-events: all;
        background: none;
        border: none;
        color: #fff;
        font-size: rem(28);
        transition: opacity .15s ease-in-out;
        opacity: .7;
        cursor: pointer;
        
        &:hover {
            opacity: 1;
        }
    }
    
    .glide__bullets {
        position: absolute;
        bottom: 12px;
        left: 0;
        width: 100%;
        text-align: center;
        pointer-events: none;
    }
    
    .glide__bullet {
        display: inline-block;
        width: rem(15);
        height: rem(15);
        border: 1px solid #fff;
        border-radius: 50%;
        background: transparent;
        margin: 0 rem(3);
        pointer-events: all;
        cursor: pointer;
        
        &--active {
            background: #fff;
        }
    }
}