.my-favorites {
    .promo-block {
        position: relative;

        .heart-btn {
            position:absolute;
            right: 15px;
            top: 8px;

            .heart {
                width: 28px;
            }
        }
    }
}

//Success Message for favorite/unfavoriting an ad
#favoriteSuccessMsg {
    position: absolute;
    margin-top: 15px;
    z-index: 800;
    padding: 10px;
    transform: translateX(-50%);
    color: #43B02A;
    background-color: white;
    border: 2px solid #43B02A;
}
#favoriteErrorMsg{
    position: absolute;
    padding: 10px;
    margin-top: 15px;
    transform: translateX(-50%);
}