.aircomplete {
    position: relative;
    z-index: 2;
    
    &-list {
        background: #fff;
        border: 1px solid #000;
        z-index: 10;
        display: none;
        max-height: 400px;
        overflow-y: auto;    
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
    }
    
    &-list-item {
        padding: 4px 12px;
        font-size: rem(14);
        
        &:hover {
            background-color: map-get($colors, gray_100);
            cursor: pointer;
        }
    }
}