@import './lib/tabset';

.tabs {
    &-nav {
        display: flex;
        justify-content: center;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            width: var(--tab-width, 0);
            height: 3px;
            display: block;
            left: 0;
            background: map-get($colors, green);
            transition: all .2s ease, transform .2s ease;
            margin-top: 0.875rem;
            height: 0.625rem;
            border-radius: 5px;
            transform: translateX(var(--tab-offset, 0));
            top: 100%;
            opacity: 0;
        }

        &.loaded:after {
            opacity: 1;
        }
    }

    &-body {
        padding: 1px 0;
    }

    &-toggle {
        font-size: rem(22);
        // font-size: rem(30);
        font-family: map-get($fonts, cricketBold);
        font-weight: 400;
        text-transform: uppercase;
        text-align: center;
        color: map-get($colors, gray_500);
        margin: 0 rem(30);
        transition: color .3s ease-in-out;
        position: relative;
        //CRICK-29 Client QA Request
        // @media only screen and (max-width: 1274px){
        //     // font-size: 1.65rem;
        //     font-size: rem(22);
        // }
        // @media only screen and (max-width: 1200px){
        //     // font-size: 1.45rem;
        //     font-size: rem(22);
        // }
        // @media only screen and (max-width: 1000px){
        //     font-size: 1.25rem;
        // }

        &:first-child {
            margin-left: 0;
            &.open{
                &:after {
                    background: map-get($colors, green);
                    // animation: loadBar .2s ease
                    // animation-fill-mode:both;
                    width: 100%;
                    right: 0;
                    left: auto;
                }
            }

        }

        &:last-child {
            margin-right: 0;
        }


        &:hover {
            color: map-get($colors, gray_700);
        }

        &.open {
            color: map-get($colors, gray_700);
        }
    }

    &-sub-toggle {
        font-size: rem(18);
        font-family: map-get($fonts, cricketMedium);
        font-weight: 400;
        margin-top: rem(6);
        text-transform: none;
        opacity: 0;
        transition: opacity .3s ease-in-out;

        .open > & {
            opacity: 1;
        }
    }

    &-mini {
        .tabs-toggle {
            font-size: rem(16);
            font-family: map-get($fonts, cricketDemibold);

            &:after {
                height: rem(4);
                border-radius: 2px;
                margin-top: rem(4);
            }
        }
    }

    @keyframes unLoadBar {
        0% { width: 100%; }
        100% { width: 0; }
    }


    @keyframes loadBar {
        0% { width: 0; }
        100% { width: 100%; }
    }

    &-nav{
        .tabs-toggle{
            margin: 0 2rem !important;
            // @media only screen and (max-width: 1247px){
            //     margin: 0 2.5rem !important;
            // }
            // @media only screen and (max-width: 1100px){
            //     margin: 0 1.5rem !important;
            // }
        }
    }

    &-switch {
        display: flex;
        flex-direction: column;
        align-items: center;

        .tabs-toggle {
            margin: 0 -20px 0 0 !important;
            padding: 6px 60px 12px;
            text-transform: none;
            color: #404041;
            margin-right: -20px;
            z-index: 2;
            line-height: 1.25;

            &:last-child {
                margin: 0 0 0 -15px !important;
            }

            &.open {
                color: #fff;
            }
        }

        .tabs-nav {
            background: #ccc;
            border-radius: 30px;
        }

        .tabs-nav:after {
            background-color: #000;
            height: 100%;
            top: 0;
            z-index: 1;
            margin: 0;
            border-radius: 30px;
        }
    }
}
