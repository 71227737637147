.container {
    width: 100%;
    max-width: map-get($layout, width);
    margin: 0 auto;
    padding: 0 map-get($layout, gutters) / 3;

    &-header {
        max-width: map-get($layout, header-width);
    }
}

.z-high,
.z-med,
.z-low {
    position: relative;
    z-index: 5;
}

.z-med {
    z-index: 3;
}

.z-low {
    z-index: 1;
}

.page-content {
    padding-bottom: 160px;
    min-height: calc(85vh);
}

.flex {
    display: flex;
    margin: -(map-get($layout, gutters) / 2);

    & + & {
        margin-top: map-get($layout, gutters) / 2;
        
        &.has-gutters-top-none {
            margin-top: 0;
        }
    }

    &-no-gutters {
        margin: 0;
    }

    &-double-gutters {
        margin: -(map-get($layout, gutters));

        & + & {
            margin-top: map-get($layout, gutters);
        }
    }
    
    &-half-gutters {
        margin: -(map-get($layout, gutters) / 4);
    }

    &-wrap {
        flex-flow: row wrap;
    }

    &-wrap-auto {
        flex-flow: row wrap;
    }

    &-direction-reverse {
        flex-direction: row-reverse;
    }

    &-direction-column {
        flex-direction: column;
    }

    &-direction-column-reverse {
        flex-direction: column-reverse;
    }

    &-space-between {
        justify-content: space-between;
    }

    &-space-around {
        justify-content: space-around;
    }

    &-align-center {
        align-items: center;
    }

    &-align-stretch {
        align-items: stretch;
    }

    &-align-start {
        align-items: flex-start;
    }

    &-align-end {
        align-items: flex-end;
    }

    &-justify-center {
        justify-content: center;
    }

    &-justify-start {
        justify-content: flex-start;
    }

    &-justify-end {
        justify-content: flex-end;
    }

    &-col {
        flex: 1 1 auto;
        flex-basis: 80px;
        padding: (map-get($layout, gutters) / 2);

        .flex-no-gutters > & {
            padding: 0;
        }

        .flex-double-gutters > & {
            padding: map-get($layout, gutters);
        }
        
        .flex-half-gutters > & {
            padding: map-get($layout, gutters) / 4;
        }
        
        .flex-wrap > & {
            @media (max-width: 767px) {
                flex: 1 1 100%;
            }
        }

        &-auto {
            flex: 0 0 auto;
        }

        &.flex-align-center {
            align-self: center;
        }

        &.flex-align-stretch {
            align-self: stretch;
        }
    
        &.flex-align-start {
            align-self: flex-start;
        }
    
        &.flex-align-end {
            align-self: flex-end;
        }

        @media (max-width: 767px) {
            &.flex-first-mobile {
                order: -1;
            }
        }

        @media (min-width: 460px) {
            @for $i from 1 through map-get($layout, columns) {
                &-mobile-#{$i} {
                    flex: 1 1 (100% / (map-get($layout, columns) / $i));
                    max-width: 100% / (map-get($layout, columns) / $i);
                }
            }
        }

        @media (min-width: 768px) {
            @for $i from 1 through map-get($layout, columns) {
                &-#{$i} {
                    flex: 1 1 (100% / (map-get($layout, columns) / $i));
                    max-width: 100% / (map-get($layout, columns) / $i);
                }
            }
        }
        
        &-menu {
            .flex-wrap > & {
                @media (max-width: 1040px) {
                    flex: 1 1 100%;
                }
            }

            @media (min-width: 1040px) {
                @for $i from 1 through map-get($layout, columns) {
                    &-#{$i} {
                        flex: 1 1 (100% / (map-get($layout, columns) / $i));
                        max-width: 100% / (map-get($layout, columns) / $i);
                    }
                }
            }
        }
    }

    @media (max-width: 767px) {
        &-mobile-block {
            display: block;
        }

        &-column-mobile {
            flex-direction: column;
        }

        &-mobile-full-width {
            width: 100%;
        }
    }
}

/* Spacing */

.has-gutters {
    margin: map-get($layout, gutters);

    &-double {
        margin: map-get($layout, gutters) * 2;
    }
    
    &-none {
        margin: 0;
    }

    &-top {
        margin-top: map-get($layout, gutters);

        &-double {
            margin-top: map-get($layout, gutters) * 2;
        }
        
        &-half {
            margin-top: map-get($layout, gutters) * 0.5;
        }

        &-none {
            margin-top: 0;
        }

        &-negative {
            margin-top: map-get($layout, gutters) * -1;
        }

        &-negative-double {
            margin-top: map-get($layout, gutters) * -2;
        }
    }

    &-bottom {
        margin-bottom: map-get($layout, gutters);

        &-double {
            margin-bottom: map-get($layout, gutters) * 2;
        }
    }

    &-left {
        margin-left: map-get($layout, gutters);

        &-double {
            margin-left: map-get($layout, gutters) * 2;
        }
    }

    &-right {
        margin-right: map-get($layout, gutters);

        &-double {
            margin-right: map-get($layout, gutters) * 2;
        }
    }
}

/* Display */

.inline {
    display: inline;
}

.inline-block {
    display: inline-block;
}

.inline-flex {
    display: inline-flex;
}

.block {
    display: block;
}

.has-margin-auto {
    margin-left: auto;
    margin-right: auto;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}


.full-height {
    height: 100%;
}

.centered {
    margin: 0 auto;
    text-align: center;
}

.has-padding-top {
    padding-top: 50px;
}

.has-padding-top-none {
    padding-top: 0px;
}

.has-padding-bottom {
    padding-bottom: 50px;
}

.relative {
    position: relative;
}

.download-link {
    margin-top: 10px;
}

.width-100 {
    width: 100%;
}

.margin-left-15 {
    margin-left: 15px;
}

.margin-0 {
    margin: 0;
}