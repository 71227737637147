body {
    font-family: map-get($fonts, cricket);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: map-get($colors, gray_700);
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    font-family: map-get($fonts, cricketBold);
    font-weight: 400;
    line-height: 1.2;
    text-transform: uppercase;

    + h1, + .h1, + h2, + .h2, + h3, + .h3, + h4, + .h4, + h5, + .h5, + h6, + .h6, + .wp-block-image {
        margin-top: rem(map-get($layout, gutters));
    }
}

h1, .h1 {
    font-size: rem(30);
}

h2, .h2 {
    font-size: rem(28);
}

h3, .h3 {
    font-size: rem(24);
}

h4, .h4 {
    font-size: rem(22);
}

h5, .h5 {
    font-size: rem(20);
}

h6, .h6 {
    font-size: rem(16);
}

p, ul, ol {
    margin: 0;

    + p, + ul, + ol {
        margin-top: rem((map-get($layout, gutters) / 2));
    }

    &.large {
        font-size: rem(24);
        line-height: 1.4;
    }
}

p, ul, ol, .wp-block-image {
    + .wp-block-image {
        margin-top: rem(map-get($layout, gutters));
    }
}

.wp-block-image {
    + h1, + .h1, + h2, + .h2, + h3, + .h3, + h4, + .h4, + h5, + .h5, + h6, + .h6, + p, + ul, + ol {
        margin-top: rem(map-get($layout, gutters));
    }
}

ol {
    list-style: decimal;
}

blockquote {
}

a {
    text-decoration: none;
    color: map-get($colors, green);

    &:hover {
        color: map-get($colors, greenShade);
    }
}

strong,
.is-bold {
    font-family: map-get($fonts, cricketBold);
    font-weight: 400;
}

i, em {
    font-style: italic;
}

.is-uppercase {
    text-transform: uppercase;
}

.is-lowercase {
    text-transform: lowercase;
}

.unset-case {
    text-transform: none !important;
}

.is-regular {
    font-weight: 400;
}

.is-cricket {
    font-family: map-get($fonts, cricket);
}

.is-cricket-bold {
    font-family: map-get($fonts, cricketBold);
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.nowrap {
    white-space: nowrap;
}

.font-inherit {
    margin: 0;
    padding: 0;
    display: inline;
    font: inherit;
    color: inherit;
    line-height: inherit;
    text-decoration: inherit;
    letter-spacing: inherit;
}

hr {
    margin: map-get($layout, gutters) 0;
    border: none;
    border-top: 1px solid map-get($colors, gray_200);
}

input[type="text"]::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-family: map-get($fonts, cricket);
}
input[type="text"]::-moz-placeholder { /* Firefox 19+ */
    font-family: map-get($fonts, cricket);
}
input[type="text"]:-ms-input-placeholder { /* IE 10+ */
    font-family: map-get($fonts, cricket);
}
input[type="text"]:-moz-placeholder { /* Firefox 18- */
    font-family: map-get($fonts, cricket);
}